<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        label="ค้นหา"
        placeholder="..."
        v-model="search"
        class="col-12"
        :validations="[
          {text: 'required!', value: $v.search.$dirty && $v.search.$error}
        ]">
      </sgv-input-text>
    </div>

    <button
      class="btn btn-primary mb-3"
      @click="fetchData">
      ค้นหา
    </button>

    <ListDeleteDocRow
      v-for="item in items"
      :key="item.id"
      :templateType="templateType"
      :auditType="auditType"
      :doc="item">
    </ListDeleteDocRow>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import ListDeleteDocRow from './ListDeleteDocRow'
import {
  LIST_DELETE_DOC
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    auditType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Audit${this.$form.capitalize(this.auditType)}ListDeleteDoc`,
      rFields: ['filter', 'options', 'search'],
      headers: [
        {text: 'รหัส', value: 'code', filter: true},
        {text: 'ชื่อ', value: 'name', filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      items: [],
      search: ''
    }
  },
  validations: {
    search: {required}
  },
  methods: {
    fetchData: debounce(function() {
      this.items = []

      this.$v.search.$touch()
      if (this.$v.search.$invalid) return

      this.$apollo.query({
        query: LIST_DELETE_DOC(this.templateType),
        variables: {
          auditType: this.auditType,
          q: {
            params: {
              search: this.search
            }
          }
        },
        fetchPolicy: 'no-cache',
      })
      .then(res => {
        this.items = res.data.items
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }, 300)
  },
  watch: {
    'search': 'setRetaining'
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    ListDeleteDocRow
  }
}
</script>

<style lang="css" scoped>
</style>
