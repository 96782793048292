<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        label="รหัสเอกสาร"
        placeholder="mnm..."
        v-model="docType"
        class="col-12"
        :validations="[
          {text: 'required!', value: $v.docType.$dirty && $v.docType.$error}
        ]">
      </sgv-input-text>
    </div>

    <button
      class="btn btn-warning"
      @click="updateData">
      อัพเดท
    </button>

    <sgv-table
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.code">
            {{item.code}}
          </td>
          <td v-if="hidden.name">
            {{item.name}}
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_LATE_DOC_APPROVE,
  FIX_LATE_DOC_APPROVE
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    auditType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Audit${this.$form.capitalize(this.auditType)}ListLateDoc`,
      rFields: ['filter', 'options', 'docType'],
      headers: [
        {text: 'รหัส', value: 'code', filter: true},
        {text: 'ชื่อ', value: 'name', filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      items: [],
      docType: ''
    }
  },
  validations: {
    docType: {required}
  },
  apollo: {
    items: {
      query () {
        return LIST_LATE_DOC_APPROVE(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          auditType: this.auditType,
          docType: this.docType
        }
      },
      debounce: 150,
      fetchPolicy: 'no-cache',
      skip () {
        return !this.docType
      }
    }
  },
  methods: {
    updateData () {
      this.$v.docType.$touch()
      if (this.$v.docType.$invalid) return

      this.$apollo.mutate({
        mutation: FIX_LATE_DOC_APPROVE(this.templateType),
        variables: {
          auditType: this.auditType,
          docType: this.docType
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.$apollo.queries.items.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
}
</script>

<style lang="css" scoped>
</style>
