<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        label="รหัสเอกสาร"
        placeholder="mnm..."
        v-model="search"
        class="col-6"
        :validations="[
          {text: 'required!', value: $v.search.$dirty && $v.search.$error}
        ]">
      </sgv-input-text>

      <sgv-input-text
        label="รหัสบัญชี (ถ้ามี)"
        placeholder="mnm..."
        v-model="accountCode"
        class="col-6">
      </sgv-input-text>
    </div>

    <button
      class="btn btn-primary mr-2"
      @click="fetchData">
      ค้นหา
    </button>

    <button
      class="btn btn-danger"
      @dblclick="updateDataAll">
      ลบทั้งหมด
    </button>

    <sgv-table
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.action">
            <button
              type="button"
              class="btn btn-sm btn-danger"
              @dblclick="updateData(item.id)">
              ลบ
            </button>
          </td>
          <td v-if="hidden.code">
            <router-link
              class="text-decoration-none"
              :to="toDoc(item)">
              {{item.code}}
            </router-link>
            <small>
              <div class="text-primary">{{item.name}}</div>
            </small>
          </td>
          <td v-if="hidden.ledgers">
            <div v-for="ledger in item.ledgers" :key="ledger.id">
              {{ledger.id}}: {{ledger.account.code}} ({{ledger.account.name}})
            </div>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_DELETE_LEDGER,
  DESTROY_DELETE_LEDGER
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    auditType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Audit${this.$form.capitalize(this.auditType)}ListDeleteLedger`,
      rFields: ['filter', 'options', 'search', 'accountCode'],
      headers: [
        {text: 'ลบ', value: 'action'},
        {text: 'รหัส', value: 'code'},
        {text: 'บัญชี', value: 'ledgers'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['action', 'code', 'ledgers'],
        column: null,
        search: null,
        toolbar: null,
      },
      items: [],
      search: '',
      accountCode: ''
    }
  },
  validations: {
    search: {required}
  },
  methods: {
    fetchData: debounce(function() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.items = []
      this.setRetaining()

      this.$apollo.query({
        query: LIST_DELETE_LEDGER(this.templateType),
        variables: {
          auditType: this.auditType,
          q: {
            params: {
              search: this.search
            }
          },
          accountCode: this.accountCode
        },
        fetchPolicy: 'no-cache',
      })
      .then(res => {
        this.items = res.data.items
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }, 300),
    updateData (docId) {
      this.$apollo.mutate({
        mutation: DESTROY_DELETE_LEDGER(this.templateType),
        variables: {
          auditType: this.auditType,
          docId,
          accountCode: this.accountCode
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$v.$reset()
        this.fetchData()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateDataAll () {
      this.items.forEach(item => {
        this.updateData(item.id)
      })
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id},
        query: {redirect: this.$route.name}
      }
    },
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
}
</script>

<style lang="css" scoped>
</style>
