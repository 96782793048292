<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        label="ประเภทเอกสาร"
        placeholder="mnm..."
        v-model="docType"
        class="col-12 col-sm-4"
        :validations="[
          {text: 'required!', value: $v.docType.$dirty && $v.docType.$error}
        ]">
      </sgv-input-text>

      <sgv-input-text
        label="รหัสเริ่มต้น"
        placeholder="PMO6401001..."
        v-model="startCode"
        class="col-6 col-sm-4">
      </sgv-input-text>

      <sgv-input-text
        label="รหัสสิ้นสุด"
        placeholder="PMO6401001..."
        v-model="endCode"
        class="col-6 col-sm-4">
      </sgv-input-text>

      <DropdownUser
        label="QC กลางวัน"
        v-model="dayUserId"
        class="col-12 col-sm-6"
        :templateType="templateType"
        :auditType="auditType"
        select="id">
      </DropdownUser>

      <DropdownUser
        label="QC กลางคืน"
        v-model="nightUserId"
        class="col-12 col-sm-6"
        :templateType="templateType"
        :auditType="auditType"
        select="id">
      </DropdownUser>
    </div>

    <sgv-table
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.code">
            <router-link
              class="text-decoration-none"
              :to="toDoc(item)">
              <span :class="getStatus(item)">
                {{item.code}}
              </span>
            </router-link>

            <button
              v-if="dayUserId && nightUserId"
              class="btn btn-warning btn-sm"
              @click="updateData(item)">
              อัพเดท
            </button>
          </td>
          <td v-if="hidden.createdAt">
            {{ item.createdAt | date }}<br>
            <small class="text-primary">{{item.createdUser.name}}</small>
          </td>
          <td v-if="hidden.approvedAt">
            <span v-if="item.approvedAt">
              <span class="text-warning">{{item.approvedAt | date}}</span><br>
              <small class="text-primary">{{item.approvedUser.name}}</small>
            </span>
          </td>
          <td v-if="hidden.closedAt">
            <span v-if="item.closedAt">
              <span class="text-success">{{item.closedAt | date}}</span><br>
              <small class="text-primary">{{item.closedUser.name}}</small>
            </span>
          </td>
          <td v-if="hidden.name">
            {{item.name}}
            <div v-if="item.remark">
              <small class="text-primary pre-line">{{ item.remark }}</small>
            </div>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import DropdownUser from './DropdownUser'
import {
  LIST_PRODUCTION_QC,
  FIX_PRODUCTION_QC
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    auditType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Audit${this.$form.capitalize(this.auditType)}ListProductionQc`,
      rFields: [
        'filter', 'options',
        'docType', 'userId', 'userType',
        'startCode', 'endCode',
        'dayUserId', 'nightUserId'
      ],
      headers: [
        {text: 'รหัส', value: 'code', filter: true},
        {text: 'วันที่สร้าง', value: 'createdAt'},
        {text: 'วันทีอนุมัติ', value: 'approvedAt'},
        {text: 'วันที่เสร็จ', value: 'closedAt'},
        {text: 'ชื่อ', value: 'name', filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'createdAt', 'approvedAt', 'closedAt', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      items: [],
      docType: '',
      startCode: '',
      endCode: '',
      dayUserId: null,
      nightUserId: null
    }
  },
  validations: {
    docType: {required},
  },
  apollo: {
    items: {
      query () {
        return LIST_PRODUCTION_QC(this.templateType)
      },
      variables() {
        this.setRetaining()

        return {
          auditType: this.auditType,
          docType: this.docType,
          startCode: this.startCode,
          endCode: this.endCode
        }
      },
      debounce: 150,
      fetchPolicy: 'no-cache',
      skip () {
        const isSkip = !this.docType

        if (isSkip) this.items = []

        return isSkip
      }
    }
  },
  methods: {
    getStatus (item) {
      if (item.approvedAt && !item.closedAt) return ['text-warning']
      else if (item.closedAt) return ['text-success']
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id},
        query: {redirect: this.$route.name}
      }
    },
    updateData (doc) {
      this.$v.docType.$touch()
      if (this.$v.docType.$invalid) return

      this.$apollo.mutate({
        mutation: FIX_PRODUCTION_QC(this.templateType),
        variables: {
          auditType: this.auditType,
          docType: this.docType,
          docId: doc.id,
          dayUserId: this.dayUserId,
          nightUserId: this.nightUserId
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.$apollo.queries.items.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DropdownUser
  }
}
</script>

<style lang="css" scoped>
</style>
