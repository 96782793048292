<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        label="รหัสเอกสาร"
        placeholder="mnm..."
        v-model="formData.docType"
        class="col-12 col-md-4"
        :validations="[
          {text: 'required!', value: $v.formData.docType.$dirty && $v.formData.docType.$error}
        ]">
      </sgv-input-text>
    </div>

    <button
      class="btn btn-primary mr-2"
      @click="fetchData">
      ค้นหา
    </button>

    <sgv-table
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.action">
            <button
              type="button"
              class="btn btn-sm btn-warning"
              @click="updateData(item.id)">
              อัพเดท
            </button>
          </td>
          <td v-if="hidden.closedAt">
            {{item.closedAt | date}}
          </td>
          <td v-if="hidden.code">
            {{item.code}}
          </td>
          <td v-if="hidden.name">
            {{item.name}}
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_DOC_CLOSEDAT_UNMATCH,
  FIX_DOC_CLOSEDAT_UNMATCH
} from './graph/doc_closedat_unmatch'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    auditType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Audit${this.$form.capitalize(this.auditType)}ListDocClosedAtUnmatch`,
      rFields: ['filter', 'options', 'formData'],
      headers: [
        {text: 'อัพเดท', value: 'action'},
        {text: 'วันที่จบ', value: 'closedAt'},
        {text: 'รหัส', value: 'code'},
        {text: 'ชื่อ', value: 'name'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['action', 'closedAt', 'code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      items: [],
      formData: {
        docType: ''
      }
    }
  },
  validations: {
    formData: {
      docType: {required}
    }
  },
  methods: {
    fetchData () {
      this.items = []
      this.setRetaining()

      this.$v.formData.$touch()

      this.$apollo.query({
        query: LIST_DOC_CLOSEDAT_UNMATCH(this.templateType),
        variables: {
          auditType: this.auditType,
          docType: this.formData.docType
        },
        fetchPolicy: 'no-cache',
      })
      .then(res => {
        this.items = res.data.items
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateData (docId) {
      this.$apollo.mutate({
        mutation: FIX_DOC_CLOSEDAT_UNMATCH(this.templateType),
        variables: {
          auditType: this.auditType,
          docId
        }
      })
      .then(res => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.removeDoc(res.data.docId)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    removeDoc (docId) {
      const index = this.items.findIndex(v => v.id === docId)
      if (index === -1) return
      this.items.splice(index, 1)
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
}
</script>

<style lang="css" scoped>
</style>
