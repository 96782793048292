import gql from 'graphql-tag'


export const LIST_STOCK_PERPETUAL_TO_PERIODIC = (templateType) => gql`query LIST_STOCK_PERPETUAL_TO_PERIODIC ($auditType: String!, $qDoc: FilterInput, $qInventory: FilterInput, $startDate: DateTime!, $endDate: DateTime!) {
  items: listAudit${templateType}StockPerpetualToPeriodic (auditType: $auditType, qDoc: $qDoc, qInventory: $qInventory, startDate: $startDate, endDate: $endDate)
}`

export const FIX_STOCK_PERPETUAL_TO_PERIODIC = (templateType) => gql`mutation FIX_STOCK_PERPETUAL_TO_PERIODIC ($auditType: String!, $ledgerId: Int!) {
  fixAudit${templateType}StockPerpetualToPeriodic (auditType: $auditType, ledgerId: $ledgerId)
}`
