var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6 col-md-3",attrs:{"label":"รหัสเอกสาร","placeholder":"mnm...","validations":[
        {text: 'required!', value: _vm.$v.formData.docType.$dirty && _vm.$v.formData.docType.$error}
      ]},model:{value:(_vm.formData.docType),callback:function ($$v) {_vm.$set(_vm.formData, "docType", $$v)},expression:"formData.docType"}}),_c('sgv-input-text',{staticClass:"col-6 col-md-3",attrs:{"label":"รหัสบัญชี","validations":[
        {text: 'required!', value: _vm.$v.formData.accountCode.$dirty && _vm.$v.formData.accountCode.$error}
      ]},model:{value:(_vm.formData.accountCode),callback:function ($$v) {_vm.$set(_vm.formData, "accountCode", $$v)},expression:"formData.accountCode"}}),_c('DropdownContact',{staticClass:"col-12 col-md-6",attrs:{"label":"ผู้ติดต่อ","templateType":_vm.templateType,"auditType":_vm.auditType,"select":"id"},model:{value:(_vm.formData.contactId),callback:function ($$v) {_vm.$set(_vm.formData, "contactId", $$v)},expression:"formData.contactId"}}),_c('sgv-input-date',{staticClass:"col-6",attrs:{"label":"เริ่ม","isNull":"","validations":[
        {text: 'required!', value: _vm.$v.formData.startDate.$dirty && _vm.$v.formData.startDate.$error}
      ]},model:{value:(_vm.formData.startDate),callback:function ($$v) {_vm.$set(_vm.formData, "startDate", $$v)},expression:"formData.startDate"}}),_c('sgv-input-date',{staticClass:"col-6",attrs:{"label":"ถึง","isNull":"","validations":[
        {text: 'required!', value: _vm.$v.formData.endDate.$dirty && _vm.$v.formData.endDate.$error}
      ]},model:{value:(_vm.formData.endDate),callback:function ($$v) {_vm.$set(_vm.formData, "endDate", $$v)},expression:"formData.endDate"}})],1),_c('button',{staticClass:"btn btn-primary mr-2",on:{"click":_vm.fetchData}},[_vm._v(" ค้นหา ")]),_c('div',{staticClass:"form-row mt-3"},[_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"รหัสบัญชีใหม่","validations":[
        {text: 'required!', value: _vm.$v.formData.newAccountCode.$dirty && _vm.$v.formData.newAccountCode.$error}
      ]},model:{value:(_vm.formData.newAccountCode),callback:function ($$v) {_vm.$set(_vm.formData, "newAccountCode", $$v)},expression:"formData.newAccountCode"}})],1),_c('button',{staticClass:"btn btn-warning mb-3",on:{"click":_vm.updateData}},[_vm._v(" อัพเดท ")]),_c('sgv-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"filter":_vm.filter,"options":_vm.options},on:{"update:filter":function($event){_vm.filter=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
      var hidden = ref.hidden;
return [_c('tr',[(hidden.approvedAt)?_c('td',[_vm._v(" "+_vm._s(_vm._f("date")(item.approvedAt))+" ")]):_vm._e(),(hidden.doc)?_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.toDoc(item.doc)}},[_vm._v(" "+_vm._s(item.doc.code)+" ")]),_c('small',[_c('div',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(item.doc.contact.code)+" ("+_vm._s(item.doc.contact.name)+") ")])])],1):_vm._e(),(hidden.account)?_c('td',[_vm._v(" "+_vm._s(item.account.code)+" ("+_vm._s(item.account.name)+") ")]):_vm._e(),(hidden.order)?_c('td',[(item.order.children.length > 0)?_c('ul',_vm._l((item.order.children.flatMap(function (c) { return c.ledgers; })),function(child){return _c('li',{key:child.id},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.toDoc(child.doc)}},[_vm._v(" "+_vm._s(child.doc.code)+" ")])],1)}),0):_vm._e()]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }