<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        label="ประเภทคลังสินค้า"
        placeholder="factory..."
        v-model="inventoryType"
        class="col-12 col-md-4"
        :validations="[
          {text: 'required!', value: $v.inventoryType.$dirty && $v.inventoryType.$error}
        ]">
      </sgv-input-text>

      <sgv-input-text
        label="ค้นหา"
        v-model="search"
        class="col-12 col-md-4">
      </sgv-input-text>
    </div>

    <button
      class="btn btn-primary mr-2"
      @click="fetchData">
      ค้นหา
    </button>

    <div class="form-row mt-3">
      <sgv-input-text
        label="ประเภทคลังสินค้า (ใหม่)"
        placeholder="factory..."
        v-model="newInventoryType"
        class="col-12 col-md-4"
        :validations="[
          {text: 'required!', value: $v.newInventoryType.$dirty && $v.newInventoryType.$error}
        ]">
      </sgv-input-text>
    </div>

    <button
      class="btn btn-warning"
      @dblclick="updateDataAll">
      อัพเดททั้งหมด
    </button>

    <sgv-table
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.action">
            <button
              type="button"
              class="btn btn-sm btn-warning"
              @click="updateData(item.id)">
              อัพเดท
            </button>
          </td>
          <td v-if="hidden.code">
            {{item.code}}
          </td>
          <td v-if="hidden.name">
            {{item.name}}
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_INVENTORY_TRANSFER,
  FIX_INVENTORY_TRANSFER
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    auditType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Audit${this.$form.capitalize(this.auditType)}ListInventoryTransfer`,
      rFields: ['filter', 'options', 'inventoryType', 'search'],
      headers: [
        {text: 'อัพเดท', value: 'action'},
        {text: 'รหัส', value: 'code'},
        {text: 'ชื่อ', value: 'name'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['action', 'code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      items: [],
      inventoryType: '',
      search: '',
      newInventoryType: ''
    }
  },
  validations: {
    newInventoryType: {required},
    inventoryType: {required},
  },
  methods: {
    fetchData: debounce(function() {
      this.items = []

      this.$v.inventoryType.$touch()
      if (this.$v.inventoryType.$invalid) return

      this.setRetaining()

      this.$apollo.query({
        query: LIST_INVENTORY_TRANSFER(this.templateType),
        variables: {
          auditType: this.auditType,
          inventoryType: this.inventoryType,
          q: {
            params: {
              search: this.search
            }
          }
        },
        fetchPolicy: 'no-cache',
      })
      .then(res => {
        this.items = res.data.items
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }, 300),
    updateData (inventoryId) {

      this.$v.newInventoryType.$touch()
      if (this.$v.newInventoryType.$invalid) return

      this.$apollo.mutate({
        mutation: FIX_INVENTORY_TRANSFER(this.templateType),
        variables: {
          auditType: this.auditType,
          inventoryType: this.inventoryType,
          inventoryId,
          newInventoryType: this.newInventoryType
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.fetchData()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateDataAll () {
      this.items.forEach(item => {
        this.updateData(item.id)
      })
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
}
</script>

<style lang="css" scoped>
</style>
