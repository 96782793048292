var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12 col-md-4",attrs:{"label":"รหัสเอกสาร","placeholder":"mnm...","validations":[
        {text: 'required!', value: _vm.$v.formData.docType.$dirty && _vm.$v.formData.docType.$error}
      ]},model:{value:(_vm.formData.docType),callback:function ($$v) {_vm.$set(_vm.formData, "docType", $$v)},expression:"formData.docType"}}),_c('sgv-input-date',{staticClass:"col-6 col-md-4",attrs:{"label":"เริ่ม","isNull":"","validations":[
        {text: 'required!', value: _vm.$v.formData.startDate.$dirty && _vm.$v.formData.startDate.$error}
      ]},model:{value:(_vm.formData.startDate),callback:function ($$v) {_vm.$set(_vm.formData, "startDate", $$v)},expression:"formData.startDate"}}),_c('sgv-input-date',{staticClass:"col-6 col-md-4",attrs:{"label":"ถึง","isNull":"","validations":[
        {text: 'required!', value: _vm.$v.formData.endDate.$dirty && _vm.$v.formData.endDate.$error}
      ]},model:{value:(_vm.formData.endDate),callback:function ($$v) {_vm.$set(_vm.formData, "endDate", $$v)},expression:"formData.endDate"}})],1),_c('button',{staticClass:"btn btn-primary mr-2",on:{"click":_vm.fetchData}},[_vm._v(" ค้นหา ")]),_c('sgv-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"filter":_vm.filter,"options":_vm.options},on:{"update:filter":function($event){_vm.filter=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
      var hidden = ref.hidden;
return [_c('tr',[(hidden.action)?_c('td',[_c('button',{staticClass:"btn btn-sm btn-warning",attrs:{"type":"button"},on:{"click":function($event){return _vm.updateData(item.id)}}},[_vm._v(" อัพเดท ")])]):_vm._e(),(hidden.closedAt)?_c('td',[_vm._v(" "+_vm._s(_vm._f("date")(item.closedAt))+" ")]):_vm._e(),(hidden.code)?_c('td',[_vm._v(" "+_vm._s(item.code)+" ")]):_vm._e(),(hidden.name)?_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }