import gql from 'graphql-tag'


export const LIST_STOCK_LEDGER_LINK = (templateType) => gql`query LIST_STOCK_LEDGER_LINK ($auditType: String!, $qDoc: FilterInput, $qInventory: FilterInput, $qAccount: FilterInput, $startDate: DateTime!, $endDate: DateTime!) {
  items: listAudit${templateType}StockLedgerLink (auditType: $auditType, qDoc: $qDoc, qInventory: $qInventory, qAccount: $qAccount, startDate: $startDate, endDate: $endDate)
}`

export const FIX_STOCK_LEDGER_LINK = (templateType) => gql`mutation FIX_STOCK_LEDGER_LINK ($auditType: String!, $orderId: Int!) {
  fixAudit${templateType}StockLedgerLink (auditType: $auditType, orderId: $orderId)
}`
