import gql from 'graphql-tag'
export * from './docLedgerSumNotZero'
export * from './inventory_transfer'
export * from './delete_doc'
export * from './delete_ledger'
export * from './stock_perpetual_to_periodic'
export * from './stock_ledger_link'


export const LIST_AUDIT_USER = (templateType) => gql`query LIST_AUDIT_USER ($auditType: String!, $q: FilterInput) {
  items: listAudit${templateType}AuditUser (auditType: $auditType, q: $q) {id name}
}`

export const LIST_AUDIT_CONTACT = (templateType) => gql`query LIST_AUDIT_CONTACT ($auditType: String!, $q: FilterInput) {
  items: listAudit${templateType}AuditContact (auditType: $auditType, q: $q) {
    id type code name branch
  }
}`

export const LIST_LATE_DOC_APPROVE = (templateType) => gql`query LIST_LATE_DOC_APPROVE ($auditType: String!, $docType: String!) {
  items: listAudit${templateType}LateDocApprove (auditType: $auditType, docType: $docType)
}`

export const FIX_LATE_DOC_APPROVE = (templateType) => gql`mutation FIX_LATE_DOC_APPROVE ($auditType: String!, $docType: String!) {
  fixAudit${templateType}LateDocApprove (auditType: $auditType, docType: $docType)
}`

export const LIST_USER_DOC = (templateType) => gql`query LIST_USER_DOC ($auditType: String!, $docType: String!, $userId: Int!, $userType: String!, $startCode: String, $endCode: String) {
  items: listAudit${templateType}UserDoc (auditType: $auditType, docType: $docType, userId: $userId, userType: $userType, startCode: $startCode, endCode: $endCode)
}`

export const FIX_USER_DOC = (templateType) => gql`mutation FIX_USER_DOC ($auditType: String!, $docType: String!, $userId: Int!, $newUserId: Int!, $userType: String!, $startCode: String, $endCode: String) {
  fixAudit${templateType}UserDoc (auditType: $auditType, docType: $docType, userId: $userId, newUserId: $newUserId, userType: $userType, startCode: $startCode, endCode: $endCode)
}`

export const LIST_PRODUCTION_QC = (templateType) => gql`query LIST_PRODUCTION_QC ($auditType: String!, $docType: String!, $startCode: String, $endCode: String) {
  items: listAudit${templateType}ProductionQc (auditType: $auditType, docType: $docType, startCode: $startCode, endCode: $endCode)
}`

export const FIX_PRODUCTION_QC = (templateType) => gql`mutation FIX_PRODUCTION_QC ($auditType: String!, $docType: String!, $docId: Int!, $dayUserId: Int!, $nightUserId: Int!) {
  fixAudit${templateType}ProductionQc (auditType: $auditType, docType: $docType, docId: $docId, dayUserId: $dayUserId, nightUserId: $nightUserId)
}`

export const FIX_TRANSACTION_DATE = (templateType) => gql`mutation FIX_TRANSACTION_DATE ($auditType: String!, $docCode: String!, $closedAt: DateTime!) {
  fixAudit${templateType}TransactionDate (auditType: $auditType, docCode: $docCode, closedAt: $closedAt)
}`


export const LIST_DOC_CODE_CLOSEDAT_UNMATCH = (templateType) => gql`query LIST_DOC_CODE_CLOSEDAT_UNMATCH ($auditType: String!, $docType: String!, $startDate: DateTime!, $endDate: DateTime!) {
  items: listAudit${templateType}DocCodeClosedAtUnmatch (auditType: $auditType, docType: $docType, startDate: $startDate, endDate: $endDate)
}`

export const FIX_DOC_CODE_CLOSEDAT_UNMATCH = (templateType) => gql`mutation FIX_DOC_CODE_CLOSEDAT_UNMATCH ($auditType: String!, $docId: Int!) {
  docId: fixAudit${templateType}DocCodeClosedAtUnmatch (auditType: $auditType, docId: $docId)
}`


export const LIST_MIGRATE_DOC_LEDGER = (templateType) => gql`query LIST_MIGRATE_DOC_LEDGER ($auditType: String!, $docType: String!, $accountCode: String!, $contactId: Int, $startDate: DateTime!, $endDate: DateTime!) {
  items: listAudit${templateType}MigrateDocLedger (auditType: $auditType, docType: $docType, accountCode: $accountCode, contactId: $contactId, startDate: $startDate, endDate: $endDate)
}`

export const FIX_MIGRATE_DOC_LEDGER = (templateType) => gql`mutation FIX_MIGRATE_DOC_LEDGER ($auditType: String!, $docType: String!, $accountCode: String!, $contactId: Int, $startDate: DateTime!, $endDate: DateTime!, $newAccountCode: String!) {
  fixAudit${templateType}MigrateDocLedger (auditType: $auditType, docType: $docType, accountCode: $accountCode, contactId: $contactId, startDate: $startDate, endDate: $endDate, newAccountCode: $newAccountCode)
}`
