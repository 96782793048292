import gql from 'graphql-tag'


export const LIST_DOC_CLOSEDAT_UNMATCH = (templateType) => gql`query LIST_DOC_CLOSEDAT_UNMATCH ($auditType: String!, $docType: String!) {
  items: listAudit${templateType}DocClosedAtUnmatch (auditType: $auditType, docType: $docType)
}`

export const FIX_DOC_CLOSEDAT_UNMATCH = (templateType) => gql`mutation FIX_DOC_CLOSEDAT_UNMATCH ($auditType: String!, $docId: Int!) {
  docId: fixAudit${templateType}DocClosedAtUnmatch (auditType: $auditType, docId: $docId)
}`
