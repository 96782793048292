import gql from 'graphql-tag'


export const LIST_DELETE_LEDGER = (templateType) => gql`query LIST_DELETE_LEDGER ($auditType: String!, $q: FilterInput, $accountCode: String) {
  items: listAudit${templateType}DeleteLedger (auditType: $auditType, q: $q, accountCode: $accountCode)
}`

export const DESTROY_DELETE_LEDGER = (templateType) => gql`mutation DESTROY_DELETE_LEDGER ($auditType: String!, $docId: Int!, $accountCode: String) {
  destroyAudit${templateType}DeleteLedger (auditType: $auditType, docId: $docId, accountCode: $accountCode)
}`
