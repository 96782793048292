<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>

      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
          <sgv-input-select
            :options="options"
            v-model="selected"
            select="value">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-select>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <component
      :is="selected"
      :templateType="templateType"
      :auditType="auditType">
    </component>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import ListLateDoc from './ListLateDoc.vue'
import ListDeleteDoc from './ListDeleteDoc.vue'
import ListDeleteLedger from './ListDeleteLedger.vue'
import ListUserDoc from './ListUserDoc.vue'
import ListProductionQc from './ListProductionQc.vue'
import ListTransactionDate from './ListTransactionDate.vue'
import ListDocCodeClosedAtUnmatch from './ListDocCodeClosedAtUnmatch.vue'
import ListDocClosedAtUnmatch from './ListDocClosedAtUnmatch.vue'
import ListMigrateDocLedger from './ListMigrateDocLedger.vue'
import ListDocLedgerSumNotZero from './ListDocLedgerSumNotZero.vue'
import ListInventoryTransfer from './ListInventoryTransfer.vue'
import ListStockPerpetualToPeriodic from './ListStockPerpetualToPeriodic.vue'
import ListStockLedgerLink from './ListStockLedgerLink.vue'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    auditType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Audit${this.$form.capitalize(this.auditType)}List`,
      rFields: ['selected'],
      options: [
        {text: 'เอกสารบันทึกย้อนหลัง', value: 'ListLateDoc'},
        {text: 'ตรวจสอบผู้บันทึก', value: 'ListUserDoc'},
        {text: 'ตรวจสอบผลิต QC', value: 'ListProductionQc'},
        {text: 'แก้ไขวันจบเอกสาร', value: 'ListTransactionDate'},
        {text: 'รหัสเอกสารไม่ตรงวันจบ', value: 'ListDocCodeClosedAtUnmatch'},
        {text: 'เอกสารไม่ตรงวันจบ', value: 'ListDocClosedAtUnmatch'},
        {text: 'บัญชีเอกสารไม่เท่ากับ 0', value: 'ListDocLedgerSumNotZero'},
        {text: 'โอนย้ายบัญชีเอกสาร', value: 'ListMigrateDocLedger'},
        {text: 'โอนย้ายคลังสินค้า', value: 'ListInventoryTransfer'},
        {text: 'ลบเอกสาร', value: 'ListDeleteDoc'},
        {text: 'ลบบัญชี', value: 'ListDeleteLedger'},
        {text: 'Perpetual to Periodic', value: 'ListStockPerpetualToPeriodic'},
        {text: 'เชื่อมโยงสต็อกกับบัญชี', value: 'ListStockLedgerLink'}
      ],
      selected: 'ListLateDoc'
    }
  },
  watch: {
    selected: 'setRetaining'
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    ListLateDoc,
    ListDeleteDoc,
    ListDeleteLedger,
    ListUserDoc,
    ListProductionQc,
    ListTransactionDate,
    ListDocCodeClosedAtUnmatch,
    ListDocClosedAtUnmatch,
    ListMigrateDocLedger,
    ListDocLedgerSumNotZero,
    ListInventoryTransfer,
    ListStockPerpetualToPeriodic,
    ListStockLedgerLink
  }
}
</script>

<style lang="css" scoped>
</style>
