<template>
  <sgv-input-select
    :options="items"
    @input="emitInput"
    :validations="validations"
    v-bind="$attrs"
    :value="value"
    select="value">
    <template slot-scope="option">
      {{option.text}} ({{option.value}})
    </template>
  </sgv-input-select>
</template>

<script>
export default {
  name: 'DropdownUserType',
  props: {
    value: {
      type: [Object, String, Number],
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
  },
  data () {
    return {
      items: [
        {text: 'สร้าง', value: 'createdBy'},
        {text: 'อนุมัติ', value: 'approvedBy'},
        {text: 'จบ', value: 'closedBy'}
      ]
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
