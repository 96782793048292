var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12 col-sm-6",attrs:{"label":"ประเภทเอกสาร","placeholder":"mnm...","validations":[
        {text: 'required!', value: _vm.$v.docType.$dirty && _vm.$v.docType.$error}
      ]},model:{value:(_vm.docType),callback:function ($$v) {_vm.docType=$$v},expression:"docType"}}),_c('DropdownUserType',{staticClass:"col-12 col-sm-6",attrs:{"label":"ประเภทผู้ใช้","validations":[
        {text: 'required!', value: _vm.$v.userType.$dirty && _vm.$v.userType.$error}
      ]},model:{value:(_vm.userType),callback:function ($$v) {_vm.userType=$$v},expression:"userType"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"รหัสเริ่มต้น","placeholder":"PMO6401001..."},model:{value:(_vm.startCode),callback:function ($$v) {_vm.startCode=$$v},expression:"startCode"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"รหัสสิ้นสุด","placeholder":"PMO6401001..."},model:{value:(_vm.endCode),callback:function ($$v) {_vm.endCode=$$v},expression:"endCode"}}),_c('DropdownUser',{staticClass:"col-12 col-sm-6",attrs:{"label":"ผู้ใช้งาน","templateType":_vm.templateType,"auditType":_vm.auditType,"select":"id","validations":[
        {text: 'required!', value: _vm.$v.userId.$dirty && _vm.$v.userId.$error}
      ]},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}}),_c('DropdownUser',{staticClass:"col-12 col-sm-6",attrs:{"label":"ผู้ใช้งานใหม่","templateType":_vm.templateType,"auditType":_vm.auditType,"select":"id","validations":[
        {text: 'required!', value: _vm.$v.newUserId.$dirty && _vm.$v.newUserId.$error}
      ]},model:{value:(_vm.newUserId),callback:function ($$v) {_vm.newUserId=$$v},expression:"newUserId"}})],1),(_vm.newUserId)?_c('button',{staticClass:"btn btn-warning",on:{"click":_vm.updateData}},[_vm._v(" อัพเดท ")]):_vm._e(),_c('sgv-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"filter":_vm.filter,"options":_vm.options},on:{"update:filter":function($event){_vm.filter=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
      var hidden = ref.hidden;
return [_c('tr',[(hidden.code)?_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.toDoc(item)}},[_c('span',{class:_vm.getStatus(item)},[_vm._v(" "+_vm._s(item.code)+" ")])])],1):_vm._e(),(hidden.createdAt)?_c('td',[_vm._v(" "+_vm._s(_vm._f("date")(item.createdAt))),_c('br'),_c('small',{staticClass:"text-primary"},[_vm._v(_vm._s(item.createdUser.name))])]):_vm._e(),(hidden.approvedAt)?_c('td',[(item.approvedAt)?_c('span',[_c('span',{staticClass:"text-warning"},[_vm._v(_vm._s(_vm._f("date")(item.approvedAt)))]),_c('br'),_c('small',{staticClass:"text-primary"},[_vm._v(_vm._s(item.approvedUser.name))])]):_vm._e()]):_vm._e(),(hidden.closedAt)?_c('td',[(item.closedAt)?_c('span',[_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(_vm._f("date")(item.closedAt)))]),_c('br'),_c('small',{staticClass:"text-primary"},[_vm._v(_vm._s(item.closedUser.name))])]):_vm._e()]):_vm._e(),(hidden.name)?_c('td',[_vm._v(" "+_vm._s(item.name)+" "),(item.remark)?_c('div',[_c('small',{staticClass:"text-primary pre-line"},[_vm._v(_vm._s(item.remark))])]):_vm._e()]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }