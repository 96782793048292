var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-date',{staticClass:"col-6",attrs:{"label":"เริ่ม","isNull":"","validations":[
        {text: 'required!', value: _vm.$v.startDate.$dirty && _vm.$v.startDate.$error}
      ]},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('sgv-input-date',{staticClass:"col-6",attrs:{"label":"ถึง","isNull":"","validations":[
        {text: 'required!', value: _vm.$v.endDate.$dirty && _vm.$v.endDate.$error}
      ]},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"ประเภทเอกสาร","placeholder":"pfo..."},model:{value:(_vm.qDoc.params.type),callback:function ($$v) {_vm.$set(_vm.qDoc.params, "type", $$v)},expression:"qDoc.params.type"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"รหัสบัญชี"},model:{value:(_vm.qAccount.params.search),callback:function ($$v) {_vm.$set(_vm.qAccount.params, "search", $$v)},expression:"qAccount.params.search"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"ประเภทสินค้า"},model:{value:(_vm.qInventory.params.type),callback:function ($$v) {_vm.$set(_vm.qInventory.params, "type", $$v)},expression:"qInventory.params.type"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"รหัสสินค้า"},model:{value:(_vm.qInventory.params.search),callback:function ($$v) {_vm.$set(_vm.qInventory.params, "search", $$v)},expression:"qInventory.params.search"}})],1),_c('button',{staticClass:"btn btn-primary mr-2",on:{"click":_vm.fetchData}},[_vm._v(" ค้นหา ")]),_c('button',{staticClass:"btn btn-warning",on:{"dblclick":_vm.updateDataAll}},[_vm._v(" อัพเดททั้งหมด ")]),_c('sgv-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"filter":_vm.filter,"options":_vm.options},on:{"update:filter":function($event){_vm.filter=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
      var hidden = ref.hidden;
return [_c('tr',[(hidden.action)?_c('td',[_c('button',{staticClass:"btn btn-sm btn-warning",attrs:{"type":"button"},on:{"click":function($event){return _vm.updateData(item.order.id)}}},[_vm._v(" อัพเดท ")])]):_vm._e(),(hidden.approvedAt)?_c('td',[_c('div',{},[_vm._v(_vm._s(_vm._f("date")(item.createdAt)))]),_c('div',{staticClass:"text-success"},[_vm._v(_vm._s(_vm._f("date")(item.approvedAt)))])]):_vm._e(),(hidden.doc)?_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.toDoc(item.doc)}},[_vm._v(" "+_vm._s(item.doc.code)+" ")]),_c('small',[_c('div',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(item.doc.contact.code)+" ("+_vm._s(item.doc.contact.name)+") ")])])],1):_vm._e(),(hidden.order)?_c('td',[_vm._v(" "+_vm._s(item.order.id)+" "),_c('span',{class:{
            'text-success': item.order.qty > 0,
            'text-danger': item.order.qty < 0
          }},[_vm._v(" ("+_vm._s(item.order.qty)+") ")]),_c('small',[_c('div',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(item.order.inventory.code)+" ("+_vm._s(item.order.inventory.name)+") ")])])]):_vm._e(),(hidden.account)?_c('td',[_vm._v(" "+_vm._s(item.account.code)+" ("+_vm._s(item.account.name)+") "),_c('small',[_c('div',{staticClass:"text-info"},[_vm._v(_vm._s(item.order.totalPrice)+" บาท")])])]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }