<template>
  <ul class="">
    <li>
      <span
        class="px-1 text-danger pointer"
        @dblclick="destroyDoc(doc.id)">
        <fa icon="trash"></fa>
      </span>
      {{doc.code}} ({{doc.name}})
    </li>
    <div
      v-for="item in items"
      :key="item.id">
      <ListDeleteDocRow
        v-for="child in item.children"
        :key="child.id"
        :templateType="templateType"
        :auditType="auditType"
        :doc="child">
      </ListDeleteDocRow>
    </div>
  </ul>
</template>

<script>
import {
  LIST_DELETE_DOC,
  DESTROY_DELETE_DOC,
} from './graph'

export default {
  name: 'ListDeleteDocRow',
  props: {
    templateType: {
      type: String,
      required: true
    },
    auditType: {
      type: String,
      required: true
    },
    doc: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      items: []
    }
  },
  methods: {
    fetchData () {
      this.items = []

      this.$apollo.query({
        query: LIST_DELETE_DOC(this.templateType),
        variables: {
          auditType: this.auditType,
          q: {
            params: {
              search: this.doc.code
            }
          }
        },
        fetchPolicy: 'no-cache',
      })
      .then(res => {
        this.items = res.data.items
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyDoc (docId) {
      this.$apollo.mutate({
        mutation: DESTROY_DELETE_DOC(this.templateType),
        variables: {
          auditType: this.auditType,
          docId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="css" scoped>
</style>
