<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        label="รหัสเอกสาร"
        v-model="formData.docCode"
        class="col-6"
        :validations="[
          {text: 'required!', value: $v.formData.docCode.$dirty && $v.formData.docCode.$error}
        ]">
      </sgv-input-text>

      <sgv-input-datetime
        label="วันที่"
        v-model="formData.closedAt"
        class="col-6"
        :validations="[
          {text: 'required!', value: $v.formData.closedAt.$dirty && $v.formData.closedAt.$error}
        ]">
      </sgv-input-datetime>
    </div>

    <button
      class="btn btn-warning"
      @click="updateData">
      อัพเดท
    </button>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import { FIX_TRANSACTION_DATE } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    auditType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Audit${this.$form.capitalize(this.auditType)}ListTransactionDate`,
      rFields: ['formData'],
      formData: {
        docCode: '',
        closedAt: ''
      }
    }
  },
  validations: {
    formData: {
      docCode: {required},
      closedAt: {required}
    }
  },
  methods: {
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: FIX_TRANSACTION_DATE(this.templateType),
        variables: {
          auditType: this.auditType,
          docCode: this.formData.docCode,
          closedAt: this.$date.format(this.formData.closedAt).raw
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
}
</script>

<style lang="css" scoped>
</style>
