var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12 col-md-4",attrs:{"label":"ประเภทคลังสินค้า","placeholder":"factory...","validations":[
        {text: 'required!', value: _vm.$v.inventoryType.$dirty && _vm.$v.inventoryType.$error}
      ]},model:{value:(_vm.inventoryType),callback:function ($$v) {_vm.inventoryType=$$v},expression:"inventoryType"}}),_c('sgv-input-text',{staticClass:"col-12 col-md-4",attrs:{"label":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('button',{staticClass:"btn btn-primary mr-2",on:{"click":_vm.fetchData}},[_vm._v(" ค้นหา ")]),_c('div',{staticClass:"form-row mt-3"},[_c('sgv-input-text',{staticClass:"col-12 col-md-4",attrs:{"label":"ประเภทคลังสินค้า (ใหม่)","placeholder":"factory...","validations":[
        {text: 'required!', value: _vm.$v.newInventoryType.$dirty && _vm.$v.newInventoryType.$error}
      ]},model:{value:(_vm.newInventoryType),callback:function ($$v) {_vm.newInventoryType=$$v},expression:"newInventoryType"}})],1),_c('button',{staticClass:"btn btn-warning",on:{"dblclick":_vm.updateDataAll}},[_vm._v(" อัพเดททั้งหมด ")]),_c('sgv-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"filter":_vm.filter,"options":_vm.options},on:{"update:filter":function($event){_vm.filter=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
      var hidden = ref.hidden;
return [_c('tr',[(hidden.action)?_c('td',[_c('button',{staticClass:"btn btn-sm btn-warning",attrs:{"type":"button"},on:{"click":function($event){return _vm.updateData(item.id)}}},[_vm._v(" อัพเดท ")])]):_vm._e(),(hidden.code)?_c('td',[_vm._v(" "+_vm._s(item.code)+" ")]):_vm._e(),(hidden.name)?_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }