<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6"
        v-model="startDate"
        label="เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.startDate.$dirty && $v.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-6"
        v-model="endDate"
        label="ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.endDate.$dirty && $v.endDate.$error}
        ]">
      </sgv-input-date>
    </div>

    <div class="form-row">
      <sgv-input-text
        label="ประเภทเอกสาร"
        placeholder="pfo..."
        v-model="qDoc.params.type"
        class="col-6">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-text
        label="รหัสบัญชี"
        v-model="qAccount.params.search"
        class="col-6">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-text
        label="ประเภทสินค้า"
        v-model="qInventory.params.type"
        class="col-6">
      </sgv-input-text>

      <sgv-input-text
        label="รหัสสินค้า"
        v-model="qInventory.params.search"
        class="col-6">
      </sgv-input-text>
    </div>

    <button
      class="btn btn-primary mr-2"
      @click="fetchData">
      ค้นหา
    </button>

    <button
      class="btn btn-warning"
      @dblclick="updateDataAll">
      อัพเดททั้งหมด
    </button>

    <sgv-table
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.action">
            <button
              type="button"
              class="btn btn-sm btn-warning"
              @click="updateData(item.order.id)">
              อัพเดท
            </button>
          </td>
          <td v-if="hidden.approvedAt">
            <div class="">{{item.createdAt | date}}</div>
            <div class="text-success">{{item.approvedAt | date}}</div>
          </td>
          <td v-if="hidden.doc">
            <router-link
              class="text-decoration-none"
              :to="toDoc(item.doc)">
              {{item.doc.code}}
            </router-link>
            <small>
              <div class="text-primary">
                {{item.doc.contact.code}} ({{item.doc.contact.name}})
              </div>
            </small>
          </td>
          <td v-if="hidden.order">
            {{item.order.id}}
            <span :class="{
              'text-success': item.order.qty > 0,
              'text-danger': item.order.qty < 0
            }">
              ({{item.order.qty}})
            </span>
            <small>
              <div class="text-primary">
                {{item.order.inventory.code}} ({{item.order.inventory.name}})
              </div>
            </small>
          </td>
          <td v-if="hidden.account">
            {{item.account.code}} ({{item.account.name}})
            <small>
              <div class="text-info">{{item.order.totalPrice}} บาท</div>
            </small>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_STOCK_LEDGER_LINK,
  FIX_STOCK_LEDGER_LINK
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    auditType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Audit${this.$form.capitalize(this.auditType)}ListStockLedgerLink`,
      rFields: ['startDate', 'endDate', 'qDoc', 'qInventory', 'qAccount'],
      headers: [
        {text: 'อัพเดท', value: 'action'},
        {text: 'วันที่', value: 'approvedAt'},
        {text: 'เอกสาร', value: 'doc'},
        {text: 'รายการ', value: 'order'},
        {text: 'บัญชี', value: 'account'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['action', 'approvedAt', 'doc', 'order', 'account'],
        column: null,
        search: null,
        toolbar: null,
      },
      items: [],
      startDate: '',
      endDate: '',
      qDoc: {
        params: {
          type: ''
        }
      },
      qInventory: {
        params: {
          type: '',
          search: ''
        }
      },
      qAccount: {
        params: {
          search: ''
        }
      }
    }
  },
  validations: {
    startDate: {required},
    endDate: {required}
  },
  methods: {
    fetchData: debounce(function() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.items = []
      this.setRetaining()

      const qDoc = {
        params: {
          type: this.qDoc.params.type
        }
      }
      const qInventory = {
        params: {
          type: this.qInventory.params.type,
          search: this.qInventory.params.search,
        }
      }
      const qAccount = {
        params: {
          search: this.qAccount.params.search,
        }
      }

      if (!qDoc.params.type) delete qDoc.params.type
      if (!qInventory.params.type) delete qInventory.params.type

      this.$apollo.query({
        query: LIST_STOCK_LEDGER_LINK(this.templateType),
        variables: {
          auditType: this.auditType,
          qInventory,
          qDoc,
          qAccount,
          startDate: this.$date.format(this.startDate).utc,
          endDate: this.$date.format(this.endDate, 0, 1).utc,
        },
        fetchPolicy: 'no-cache',
      })
      .then(res => {
        this.items = res.data.items
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }, 300),
    updateData (orderId) {
      this.$apollo.mutate({
        mutation: FIX_STOCK_LEDGER_LINK(this.templateType),
        variables: {
          auditType: this.auditType,
          orderId
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.$v.$reset()
        this.fetchData()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateDataAll () {
      this.items.forEach(item => {
        this.updateData(item.order.id)
      })
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id},
        query: {redirect: this.$route.name}
      }
    },
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
