import gql from 'graphql-tag'


export const LIST_INVENTORY_TRANSFER = (templateType) => gql`query LIST_INVENTORY_TRANSFER ($auditType: String!, $inventoryType: String!, $q: FilterInput) {
  items: listAudit${templateType}InventoryTransfer (auditType: $auditType, inventoryType: $inventoryType, q: $q)
}`

export const FIX_INVENTORY_TRANSFER = (templateType) => gql`mutation FIX_INVENTORY_TRANSFER ($auditType: String!, $inventoryType: String!, $inventoryId: Int!, $newInventoryType: String!) {
  fixAudit${templateType}InventoryTransfer (auditType: $auditType, inventoryType: $inventoryType, inventoryId: $inventoryId, newInventoryType: $newInventoryType)
}`
